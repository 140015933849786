
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import { Dictionary } from "vue-router/types/router";
import tablePagination from "@/mixins/table-pagination";
import tableFilters from "@/mixins/table-filters";
import { parseArrayParams } from "@/services/helpers";

import DateRangePicker from "@/components/form-components/DateRangePicker.vue";

export default Vue.extend({
  name: "ActivityLog",

  components: { DateRangePicker },

  mixins: [tablePagination, tableFilters],

  data: () => ({
    parseArrayParams,
    filter: {} as Dictionary<string | (string | null)[]>,
    headers: [] as Array<any>,
    items: [] as Array<any>,
    selects: {
      managers: []
    } as any,
    loading: false as boolean
  }),

  async beforeRouteEnter(to, from, next) {
    try {
      const params = {
        filter: to.query,
        page: {
          number: to.params.page,
          size: 20
        }
      };
      const response = await API.user().getLogs(params);
      const managersList = await API.archive().getManagersList();

      next(vm => {
        vm.setFilters(params.filter);
        vm.setServerResponse(response);
        vm.selects.managers = managersList;
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        const response = await this.$API.user().getLogs({
          filter: this.parseArrayParams(this.filter),
          page: this.pagination,
          sort: this.getSortParams()
        });
        const managersList = await API.archive().getManagersList();

        this.setServerResponse(response);
        this.selects.managers = managersList;
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    setFilters(filter: Dictionary<string | (string | null)[]>): void {
      const newFilters = this.getAvailableFilters(filter);

      if (Object.keys(newFilters).length) {
        this.showFilters = true;
      }

      if (newFilters.hasOwnProperty("date")) {
        newFilters.created_at = newFilters.date.split(",");
      }

      this.filter = newFilters;
      this.changeActiveFilters();
    },
    setServerResponse({ items, headers }: any) {
      this.headers = headers;
      this.items = items.data;
      this.totalItems = items.total;
    }
  }
});
